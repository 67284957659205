@font-face {
  font-family: "PPNeueMontreal-Medium";
  src: url("../../PPNeueMontreal-Medium.otf") format("opentype");
}


.home-img-main {
  overflow: hidden;
  max-width: 100%;
  margin-top: 20px;
  text-align: center;
  filter: grayscale(50%) brightness(50%);
}

.post-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

h3 {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
}

p {
  font-size: 16px;
  color: gray;
}


body {
  color: var(--color-white);
  font-family: "PPNeueMontreal-Medium", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-black);
}

:root {
  --color-black: rgb(21, 21, 21);
  --color-white: #ffffff;
  --color-gray-400: rgb(128, 129, 130);
  --flex-gap-text: 0.75rem;
}

.text-regular {
  text-transform: uppercase;
  color: var(--color-white);
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.container {
  width: 100vw;
  padding: 13em 4em 4em 4em !important;
}

.text-is-gray {
  color: var(--color-gray-400) !important;
}

.text-is-black {
  color: var(--color-black) !important;
}

.title-one {
  text-transform: uppercase;
  font-size: 5rem;
  font-weight: 400;
}

.text-small {
  text-transform: uppercase;
  font-size: .9em;
  font-weight: 400;
}

.title-two {
  text-transform: uppercase;
  font-size: 3.5rem;
  font-weight: 400;
}

.line-is-gray {
  width: 100%;
  margin: 1em 0em;
  height: 1px;
  background-color: var(--color-gray-400);
}



/* .underline-wrapper {
  cursor: pointer;
} */

.underline-wrapper:hover .underline-effect {
  width: 100%;
}

.underline-effect {
  position: relative;
  background-color: var(--color-black);
  width: 0%;
  height: 1px;
  transform-origin: left;
  transition: width .3s ease-in-out;
}