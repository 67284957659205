.gallery-container {
    padding: 2em;
    display: flex;
    flex-direction: column;
    /* grid-template-columns: repeat(3, 1fr); */
    row-gap: 0.5em;
    height: max-content;
}

.gallery-element-type-one {
    height: 600px;
    grid-column: span 3;
}

.gallery-element-type-one .divider {
    display: block;
    height: 600px;
}

.gallery-element-type-one .divider img {
    display: block;
    height: 600px;
}

.gallery-element-type-two {
    grid-gap: .5em;
    display: grid;
    gap: .5em;
    grid-column: span 2;
    grid-template-columns: repeat(2, 1fr);
}

.gallery-element-type-two .divider {
    display: block;
    height: 450px;
}

.gallery-element-type-two .divider img {
    display: block;
    height: 450px;
}


.gallery-element-type-three {
    grid-column: span 3;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5em;
    height: 300px;
}

.gallery-element-type-three .divider {
    display: block;
    height: 300px;
}

.gallery-element-type-three .divider img {
    display: block;
    height: 300px;
}

.divider-img {
    border-radius: .5em;
    width: 100%;
    height: auto;
    overflow: hidden;
}

.divider-img>img {
    filter: grayscale(100%) brightness(50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.5s;
}

.divider {
    position: relative;
}

.divider-img>img:hover {
    filter: grayscale(25%) brightness(75%);

}

.divider-title {
    pointer-events: none;
    width: 60%;
    position: absolute;
    z-index: 2;
    left: 2em;
    top: 2em;
    color: white;
    opacity: 0.55;
}

@media screen and (max-width: 862px) {
    .gallery-container {
        grid-template-columns: 1fr;
    }

    .gallery-element-type-one,
    .gallery-element-type-two,
    .gallery-element-type-three {
        grid-column: span 1;
    }

    .gallery-element-type-three .divider,
    .gallery-element-type-three .divider img {
        display: block;
        height: 300px;
    }

    .gallery-element-type-two .divider,
    .gallery-element-type-two .divider img {
        display: block;
        height: 300px;
    }


    .gallery-element-type-one .divider,
    .gallery-element-type-one .divider img {
        display: block;
        height: 300px;
    }

    .gallery-element-type-one {
        grid-template-columns: 1fr;
        height: 300px;
    }


    .gallery-element-type-two {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .gallery-element-type-three {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}


@media screen and (max-width: 768px) {
    .gallery-container {
        grid-template-columns: 1fr;
    }

    .gallery-element-type-one,
    .gallery-element-type-two,
    .gallery-element-type-three {
        grid-column: span 1;
    }

    .gallery-element-type-three .divider,
    .gallery-element-type-three .divider img {
        display: block;
        height: 200px;
    }

    .gallery-element-type-two .divider,
    .gallery-element-type-two .divider img {
        display: block;
        height: 200px;
    }


    .gallery-element-type-one .divider,
    .gallery-element-type-one .divider img {
        display: block;
        height: 200px;
    }

    .gallery-element-type-one {
        grid-template-columns: 1fr;
        height: 200px;
    }


    .gallery-element-type-two {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .gallery-element-type-three {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}