.gallery-detail-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 50vh auto auto;
    padding-top: 8em;
    padding-left: 2.5em;
    padding-right: 2.5em;
    width: 100vw;
    overflow: hidden;
    justify-items: center;
}

.gallery-detail-header {
    /* padding: 0em 8em; */
    width: 80vw;
    display: flex;
    flex-direction: column;
    gap: 1em;
    height: 20vh;
    margin-top: 5em;
    margin-bottom: 1em;
}

/* .nav-bar-title {
    filter: opacity(0);
    pointer-events: none;
} */


.gallery-detail-header .title-one {
    line-height: 1em;
    word-break: break-word;
}

.gallery-detail-header h2 {
    font-size: 1.5em;
    color: gray;
}

.gallery-detail-main {
    display: flex;
    height: 50.2vw;
}

.main-image {
    flex: 1;
    margin-right: 1em;

}

.main-image img {
    width: 100%;
    height: auto;
    border-radius: 0.5em;
}

.thumbnail-images {
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 100%;
    overflow-y: scroll;
}

.thumbnail-images img {
    width: 100%;
    height: auto;
    margin-bottom: 1em;
    border-radius: 0.5em;
    transition: transform 0.3s;
}

/* .thumbnail-images img:hover {
    filter: grayscale(0.5);
} */

.album-details {
    display: flex;
    font-size: 1.25em;
    gap: .5em;

}

.gallery-detail-specs {
    /* padding: 0em 8em; */
    width: 80vw;
    padding-bottom: 2.5em;
    height: fit-content;
}

.gallery-detail-credit {
    /* padding: 0em 8em; */
    width: 80vw;
    margin-bottom: 2em;
    height: fit-content;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.3s ease-in;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

@media screen and (max-width: 768px) {
    .gallery-detail-main {
        width: 90vw;
        display: flex;
        flex-direction: column;
        height: fit-content;
    }

    .gallery-detail-main>img {
        width: 100%;
        height: auto;
        border-radius: 0.5em;
    }

    .main-image {
        flex: 1;
        margin-right: 0;
        margin-bottom: .5em;

    }

    .thumbnail-images {
        display: flex;
        flex-direction: row;
        height: 160px;
        width: 90vw;
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .thumbnail-images img {
        height: auto;
        width: auto;
        margin-right: 1em;
    }

    .gallery-detail-header {
        width: 90vw;
    }

    .gallery-detail-credit {
        width: 90vw;
    }

    .gallery-detail-specs {
        width: 90vw;
    }

}