.img-nav-bar {
    z-index: 4;
    position: fixed;
    bottom: 3em;
    width: 100%;
    display: flex;
    justify-content: center;
}

.img-nav-container {
    text-transform: uppercase;
    height: 4em;
    border-radius: 2em;
    padding: 10px;
    width: 400px;
    display: flex;
    justify-content: space-evenly;
    gap: 1.1em;
    align-items: center;
    color: black !important;
    background-color: white;
    position: relative;
}

.img-nav-active-box {
    position: absolute;
    top: .25em;
    bottom: 0;
    left: 0px;
    background-color: black;
    border-radius: 2em;
    height: 3.5em;
    z-index: 1;
    width: 0;
}

.img-nav-filter {
    z-index: 2;
    width: max-content + 1em;
    padding: 0 1em;
}

.img-nav-filter-active {
    color: white;
}

/* @media screen and (max-width: 768px) {
    .img-nav-bar {
        z-index: 4;
        position: fixed;
        bottom: 3em;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .img-nav-filter {
        z-index: 2;
        width: max-content + 1em;
        padding: 0 1em;
        font-size: 1em;
    }

    .img-nav-container {
        width: 80%;
        gap: .5em;
    }

} */