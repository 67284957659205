@font-face {
  font-family: "PPNeueMontreal-Medium";
  src: url("../../PPNeueMontreal-Medium.otf") format("opentype");
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  color: var(--color-white);
  font-family: "PPNeueMontreal-Medium", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-black);
}

:root {
  --color-black: rgb(21, 21, 21);
  --color-white: #ffffff;
  --color-gray-400: rgb(128, 129, 130);
  --flex-gap-text: 0.75rem;
}

.text-regular {
  text-transform: uppercase;
  color: var(--color-white);
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.no-margin {
  margin: 0 !important;
}

.small-margin {
  margin: .5em 0em !important;
}

.big-margin {
  margin: 1em 0em !important;
}

.container {
  width: 100vw;
  padding: 13em 4em 0em 4em;
}

.text-is-gray {
  color: var(--color-gray-400) !important;
}

.text-is-black {
  color: var(--color-black) !important;
}

.title-one {
  text-transform: uppercase;
  font-size: 5rem;
  font-weight: 400;
}

.text-small {
  text-transform: uppercase;
  font-size: .9em;
  font-weight: 400;
}

.title-two {
  text-transform: uppercase;
  font-size: 3.5rem;
  font-weight: 400;
}

.title-three {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 400;
}

.line-is-gray {
  width: 100%;
  margin: 1em 0em;
  height: 1px;
  background-color: var(--color-gray-400);
}



/* .underline-wrapper {
  cursor: pointer;
} */

.underline-wrapper:hover .underline-effect {
  width: 100%;
}

.underline-effect {
  position: relative;
  background-color: var(--color-black);
  width: 0%;
  height: 1px;
  transform-origin: left;
  transition: width .3s ease-in-out;
}

.About {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  justify-items: center;
}


.about-cover-img-container {
  padding: 2em;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.about-cover-img-container>img {
  border-radius: 1em;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%) brightness(75%);
}

.about-cover-title {
  position: absolute;
  bottom: .75em;
  left: .75em;
}

.about-content {
  width: 80vw;
  padding-bottom: 2em;
}

.about-section-feat-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1024px) {
  .about-content {
    padding: 5em;
  }
}

@media screen and (max-width: 756px) {
  .about-content {
    padding: 0;
  }
  .about-cover-img-container {
    padding: 1.5em;
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
  }
  .about-cover-title {
    position: absolute;
    bottom: .75em;
    left: .75em;
    font-size: 2.5rem !important;
  }
  
}

