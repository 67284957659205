body {
    cursor: none;
}

.cursor {
    z-index: 99999;
    /* width: 20px;
    height: 20px; */
    background-color: #ffffff;
    mix-blend-mode: difference;
    position: fixed;
    border-radius: 100%;
    pointer-events: none;
    transition: width 0.5s ease, height 0.5s ease, transform 0.5s ease;
    will-change: width, height, transform;
}

/* 
.cursor.hovered {
    width: 50px;
    height: 50px;
    transform: scale(10);
} */