.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;
    opacity: 1;
    z-index: 100000;
}

.loading-progress {
    font-size: 6em;
    margin-bottom: .25em;
}

.loading-bar-container {
    width: 100%;
    height: 10px;
    /* border-radius: 1em; */
    background-color: #e0e0e0;
    overflow: hidden;
    margin-bottom: 2em;
}

.loading-bar {
    height: 100%;
    /* border-radius: 1em; */
    background-color: black;
    transition: width 0.5s ease;
}
