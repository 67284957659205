.blocks-container {
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    pointer-events: none;
}

.row {
    flex: 1;
    width: 100%;
    display: flex;
}

.block {
    position: relative;
    flex: 1;
    background: var(--color-gray-500);
    margin: -0.25px;
}

.transition-in .block {
    transform-origin: top;
}

.transition-out .block {
    transform-origin: bottom;
}