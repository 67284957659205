@font-face {
  font-family: "PPNeueMontreal-Medium";
  src: url("PPNeueMontreal-Medium.otf") format("opentype");
}

* {
  box-sizing: border-box;
  margin: 0;
}

body {
  color: var(--color-white);
  font-family: "PPNeueMontreal-Medium", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-black);
}

.line {
  overflow: hidden;
  line-height: 115%;
}

a:hover {
  cursor: none;
}

*::-webkit-scrollbar {
  display: none !important;
}

* {
  scrollbar-width: none !important;
}

* {
  -ms-overflow-style: none !important;
}


:root {
  --color-black: #141414;
  /* --color-black: #000000; */
  --color-white: #ebebeb;
  --color-gray-400: rgb(128, 129, 130);
  --color-gray-500: rgb(18, 18, 18);
  --color-gray-300: rgb(53, 53, 53);
  --flex-gap-text: 0.75rem;
  --color-red: #d54343;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

.text-regular {
  text-transform: inherit;
  color: var(--color-white);
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

.container {
  width: 100vw;
  padding: 13em 4em 0em 4em;
}

.text-is-gray {
  color: var(--color-gray-400) !important;
}

.text-is-gray-300 {
  color: var(--color-gray-300) !important;
}

.text-is-white {
  color: rgb(255, 255, 255) !important;
}

.title-one {
  text-transform: inherit;
  font-size: 5rem;
  font-weight: 400;
  line-height: 1.1em;
}

.text-small {
  text-transform: inherit;
  font-size: .9em;
  font-weight: 400;
}

.title-two {
  text-transform: inherit;
  font-size: 3.5rem;
  font-weight: 400;
}

.title-three {
  text-transform: inherit;
  font-size: 2rem;
  font-weight: 400;
}

.text-bigger {
  text-transform: inherit;
  font-size: 1.35rem;
  font-weight: 400;
}

.line-is-gray {
  width: 100%;
  margin: 1em 0em;
  height: 1px;
  background-color: var(--color-gray-400);
}

.line-is-gray-300 {
  width: 100%;
  margin: 1em 0em;
  height: 1px;
  background-color: var(--color-gray-300);
}

.color-red {
  color: var(--color-red);
}

.line-space {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.margin-tb {
  margin-top: 1em;
  margin-bottom: 1em;
}

.margin-bl {
  margin-bottom: 3em;
}


@media screen and (max-width: 768px) {
  .title-one {
    text-transform: inherit;
    font-size: 7vw !important;
    font-weight: 400;
  }

  .title-two {
    text-transform: inherit;
    font-size: 1.75rem !important;
    font-weight: 400;
  }

  .text-regular {
    text-transform: inherit;
    color: var(--color-white);
    font-size: 1rem !important;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .text-bigger {
    text-transform: inherit;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .title-three {
    text-transform: inherit;
    font-size: 1.2rem;
    font-weight: 400;
  }

  input {
    border: none;
    width: 80vw;
    height: 4em;
    background-color: transparent;
    color: var(--color-gray-400) !important;
    font-size: 1em;
  }

  input::placeholder {
    color: var(--color-gray-400) !important;
    font-size: 1em;
  }

}


/* .underline-wrapper {
  cursor: pointer;
} */

.underline-wrapper:hover .underline-effect {
  width: 100%;
}

.underline-wrapper:hover .underline-effect-white {
  width: 100%;
}

.underline-effect {
  position: relative;
  background-color: var(--color-gray-400);
  width: 0%;
  height: 1px;
  transform-origin: left;
  transition: width .3s ease-in-out;
}

.underline-effect-white {
  position: relative;
  background-color: var(--color-white);
  width: 0%;
  height: 1px;
  transform-origin: left;
  transition: width .3s ease-in-out;
}

input {
  border: none;
  width: 80vw;
  height: 4em;
  background-color: transparent;
  color: var(--color-gray-400) !important;
  font-size: 2em;
}

input::placeholder {
  color: var(--color-gray-400) !important;
  font-size: 1em;
}

input:focus {
  outline: none;
}

.contact-field {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.contact-inner {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  gap: 5vw;
}

.contact-container {
  padding-top: 20em;
}

.see-all-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: .25em;
}