.nav-bar {
  z-index: 999;
  position: fixed;
  display: flex;
  top: 5em;
  right: 5em;
  padding-left: 10em;
  padding-bottom: 0em;
  width: 100vw;
}

.nav-bar-title {
  mix-blend-mode: difference;
  z-index: 999;
  width: 100%;
  display: flex;
  gap: var(--flex-gap-text);
}

.menu-button {
  text-transform: uppercase;
}

.nav-menu {
  clip-path: circle(25% at 100% 50%);
  z-index: 998;
  right: 0%;
  top: 0;
  /* top: 50vh; */
  opacity: 1;
  position: fixed;
  height: 100dvh;
  width: 35vw;
  background-color: var(--color-white);
}

.menu-button-container {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  height: 20px;
  mix-blend-mode: difference;
  z-index: 999;
}


a {
  text-decoration: none;
  color: var(--color-gray-500);
}

.nav-menu-inner {
  padding: 5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5em;
  height: 100%;
}

.nav-socials {
  display: flex;
  flex-direction: column;
  gap: .75em;
}

.nav-socials-inner {
  display: flex;
  gap: 1em;
}

@media screen and (max-width: 768px) {
  .nav-menu {
    width: 100vw;
  }

}

.title-nav {
  font-size: 3.5rem;
  font-weight: 400;
  text-transform: capitalize;
}

.menu-links::after {
  content: '';
  display: block;
  position: absolute;
  border-radius: 5em;
  width: 0px;
  height: 0px;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
  background-color: rgb(0, 0, 0) !important;
  transition: all 0.3s ease;
  opacity: 0;
}

.menu-links:hover::after {
  width: 10px;
  height: 10px;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .nav-bar-title {
    filter: opacity(0);
    pointer-events: none;
  }

  .nav-menu-inner {
    padding-left: 2em;
    padding-right: 2em;
  }
}


@media screen and (max-width: 1350px) {
  .nav-bar-title {
    filter: opacity(0);
    pointer-events: none;
  }

  .nav-menu {
    clip-path: circle(25% at 100% 50%);
    z-index: 998;
    right: 0%;
    top: 0;
    /* top: 50vh; */
    opacity: 1;
    position: fixed;
    height: 100dvh;
    width: 100vw;
    background-color: var(--color-white);
  }

}