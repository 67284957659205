.footer {
  padding: 2em 4em;
  width: 100vw;
}

.footer-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer-credit {
  display: flex;
  gap: .5em;
}