@font-face {
  font-family: "PPNeueMontreal-Medium";
  src: url("../../PPNeueMontreal-Medium.otf") format("opentype");
}


.home-img-main {
  overflow: hidden;
  max-width: 100%;
  margin-top: 20px;
  text-align: center;
  filter: grayscale(50%) brightness(50%);
}

.post-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

h3 {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
}

p {
  font-size: 16px;
  color: gray;
}


body {
  color: var(--color-white);
  font-family: "PPNeueMontreal-Medium", sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-black);
}

:root {
  --color-black: rgb(21, 21, 21);
  --color-white: #ffffff;
  --color-gray-400: rgb(128, 129, 130);
  --color-gray-500: rgb(43, 43, 43);
  --flex-gap-text: 0.75rem;
}



/* .underline-wrapper {
  cursor: pointer;
} */

.underline-wrapper:hover .underline-effect {
  width: 100%;
}

.underline-effect {
  position: relative;
  background-color: var(--color-black);
  width: 0%;
  height: 1px;
  transform-origin: left;
  transition: width .3s ease-in-out;
}